import { CheckCircle, Upload, Warning } from "@mui/icons-material"
import { Button, SvgIconProps } from "@mui/material"
import { FC, useState } from "react"
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context"
import { UnsyncedScansDialog } from "src/attendance/UnsyncedScansDialog"
import { BackgroundUpdateState } from "src/common/background-update/background-update-storage"
import { useFormContext } from "src/forms/form-context"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"


export const UploadStatusIcon: FC<SvgIconProps> = ({
    ...props
}) => {
    const attendance = useAttendanceTracker()
    const forms = useFormContext()
    const uploads = [
        ...attendance.queuedScans,
        ...forms.queuedResponses,
    ]
    const [isUnsyncedScansDialogOpen, setIsUnsyncedScansDialogOpen] = useState(false)
    const overlay = useInteractionOverlay()

    if (uploads.some(it => it.state === BackgroundUpdateState.Failed)) {
        return <Warning color="warning" {...props} />
    }
    if (uploads.length > 0) {
        return <>
            <UnsyncedScansDialog open={isUnsyncedScansDialogOpen} onClose={() => setIsUnsyncedScansDialogOpen(false)} />
            <Button onClick={() => setIsUnsyncedScansDialogOpen(true)} disabled={overlay.isOpen}>View unsynced scans</Button>
            <Upload color="info" {...props} />
        </>
    }
    return <CheckCircle color="success" {...props} />
}