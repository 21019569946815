import { Registration } from "@marketpartner/backend-api"
import { fullName } from "@marketpartner/mp-common"
import CheckIcon from "@mui/icons-material/Check"
import { Stack, Typography, useTheme } from "@mui/material"
import { FC, useEffect } from "react"
import { FullscreenScanResultOverlay } from "src/interaction/FullscreenScanResultOverlay"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"

export type VerifiedOverlayProps = {
    registration: Registration
}

export const FullscreenVerifiedOverlay: FC<VerifiedOverlayProps> = ({
    registration,
}) => {
    const { close } = useInteractionOverlay()
    const theme = useTheme()

    const lanyardColor = getLanyardColor(registration)

    useEffect(() => {
        const timer = setTimeout(close, lanyardColor ? 4_000 : 2_000)
        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close])

    return <FullscreenScanResultOverlay
        color={theme.palette.success.main}
    >
        <Stack sx={{
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.success.contrastText,
        }}>
            <CheckIcon sx={{
                fontSize: "50vmin",
            }} />
            <Typography fontSize="10vmin" sx={{
                textAlign: "center"
            }}>
                {fullName(registration)} {getBadgeType(registration)}
            </Typography>
            {lanyardColor && <Typography fontSize="8vmin" sx={{
                textAlign: "center"
            }}>
                Please take a {lanyardColor} lanyard
            </Typography>}
        </Stack>
    </FullscreenScanResultOverlay>
}

function getBadgeType(registration: Registration): string {
    if (
        'badge_type' in registration.fields &&
        typeof registration.fields.badge_type === 'string' &&
        registration.fields.badge_type !== ""
    ) {
        return registration.fields.badge_type
    }
    return ""
}

function getLanyardColor(registration: Registration): string | undefined {
    if (
        'lanyard_color' in registration.fields &&
        typeof registration.fields.lanyard_color === 'string' &&
        registration.fields.lanyard_color !== ""
    ) {
        return registration.fields.lanyard_color
    }
    return undefined
}