import { createTheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { Box, ThemeProvider } from '@mui/system'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'
import { HashRouter } from 'react-router-dom'
import { AppUpdatePopup } from 'src/app-updates/AppUpdatePopup'
import { AppUpdateProvider } from 'src/app-updates/app-update-context'
import { AttendanceTrackerContext } from 'src/attendance/attendance-tracker-context'
import { EventContextProvider } from 'src/common/event-context'
import { SelectionDispatchContextProvider } from 'src/dispatch/selection-dispatch-context'
import { FormContextProvider } from 'src/forms/form-context'
import { InteractionOverlayContextProvider } from 'src/interaction/interaction-overlay-context'
import { AppLayout } from 'src/layout/AppLayout'
import { LockingContextProvider } from 'src/locking/locking-context'
import { Login } from 'src/login/login-context'
import { PrintingContextProvider } from 'src/printing/printing-context'
import { RegistrationsProvider } from 'src/registrations/registrations-context'
import { ScannerSettingsProvider } from 'src/settings/scanner-settings-context'
import { ScanningConfigurationProvider } from 'src/settings/scanning-configuration-context'
import './App.css'

const theme = createTheme({
    palette: {
        mode: "dark"
    }
})

const queryClient = new QueryClient()

LicenseInfo.setLicenseKey('befd6d8a00721be477b09c2079a03ca3Tz03NTE2MSxFPTE3MjY5MzI0NTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

function App() {
    return <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
            <HashRouter>
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "background.default",
                }}>
                    <SnackbarProvider
                        maxSnack={3}
                        preventDuplicate
                    >
                        <Login>
                            <AppUpdateProvider>
                                <AppUpdatePopup />
                                <EventContextProvider>
                                    <ScannerSettingsProvider>
                                        <LockingContextProvider>
                                            <FormContextProvider>
                                                <ScanningConfigurationProvider>
                                                    <RegistrationsProvider>
                                                        <PrintingContextProvider>
                                                            <AttendanceTrackerContext>
                                                                <SelectionDispatchContextProvider>
                                                                    <InteractionOverlayContextProvider>
                                                                        <AppLayout />
                                                                    </InteractionOverlayContextProvider>
                                                                </SelectionDispatchContextProvider>
                                                            </AttendanceTrackerContext>
                                                        </PrintingContextProvider>
                                                    </RegistrationsProvider>
                                                </ScanningConfigurationProvider>
                                            </FormContextProvider>
                                        </LockingContextProvider>
                                    </ScannerSettingsProvider>
                                </EventContextProvider>
                            </AppUpdateProvider>
                        </Login>
                    </SnackbarProvider>
                </Box>
            </HashRouter>
        </ThemeProvider>
    </QueryClientProvider>
}

export default App
