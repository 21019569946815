import { buildBackend } from '@marketpartner/backend-api'
import { buildBackendHooks } from '@marketpartner/mp-common-react'
import { findAccessToken } from 'src/login/useTokenLogin'

export const legacyBackend = buildBackend({
    credentialsProvider: () => {
        const accessToken = findAccessToken()
        return accessToken ? {
            type: "AccessToken",
            accessToken
        } : undefined
    }
})
export const backend = buildBackendHooks({
    credentialsProvider: () => {
        const accessToken = findAccessToken()
        return accessToken ? {
            type: "AccessToken",
            accessToken
        } : undefined
    }
})