import { Box, Button, DialogActions, DialogContent, DialogProps, DialogTitle, Stack } from "@mui/material"
import { FC } from "react"
import { ResponsiveDialog } from "src/common/ResponsiveDialog"
import { ResponsiveIconButton } from "src/common/ResponsiveIconButton"
import CloseIcon from "@mui/icons-material/Close"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { DataGridPro } from "@mui/x-data-grid-pro"
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context"
import { useFormContext } from "src/forms/form-context"


export type UnsyncedScansDialogProps = Omit<DialogProps, "onClose"> & {
    onClose: () => unknown
}

export const UnsyncedScansDialog: FC<UnsyncedScansDialogProps> = ({
    onClose,
    ...props
}) => {
    return <ResponsiveDialog
        onClose={() => onClose()}
        maxWidth="sm"
        fullWidth
        {...props}
        PaperProps={{
            sx: {
                height: "100%"
            }
        }}
    >
        <UnsyncedScansDialogContent onClose={onClose} />
    </ResponsiveDialog>
}

const UnsyncedScansDialogContent: FC<{
    onClose: () => unknown
}> = ({ onClose }) => {
    const attendance = useAttendanceTracker()
    const forms = useFormContext()
    const uploads = [
        ...attendance.queuedScans,
        ...forms.queuedResponses,
    ]
    return <>
        <Stack flexDirection="row" sx={{
            alignItems: "center",
        }}>
            <DialogTitle>
                Unsynced scans
            </DialogTitle>
            <Box sx={{ flex: "1" }} />
            <ResponsiveIconButton onClick={() => onClose()} sx={{ m: 1 }}>
                <CloseIcon />
            </ResponsiveIconButton>
        </Stack>
        <DialogContent>
            <DataGridPro
                rows={uploads.map(it => ({
                    id: it.id,
                    token: it.item.token,
                    time: 'time' in it.item ? it.item.time : "",
                    state: it.state
                }))}
                columns={['token', 'time', 'state'].map(field => ({
                    field,
                    headerName: field,
                    flex: 1,
                    editable: false,
                }))}
            />
        </DialogContent>
        <DialogActions>
            <Button
                startIcon={<ContentCopyIcon />}
                disabled={uploads.length === 0}
                onClick={() => navigator.clipboard.writeText(JSON.stringify(uploads))}
            >
                Copy to clipboard
            </Button>
        </DialogActions>
    </>
}